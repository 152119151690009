import React from 'react';
import { Navbar, Container } from 'react-bootstrap';
import logo from '../../assets/icons/logo.svg';
import './header.css';
function Header() {
  return (
    <Navbar className="customHeader" expand="lg">
      <Container>

      <Navbar.Brand>
      <div className="d-flex align-items-center">
          <img
            src={logo}
            width="67"
            height="67"
            className="d-inline-block align-top"
            alt="Logo"
          />
          <span className="ms-4 nameService">Веб-сервис онлайн-голосования</span>
           </div>
        </Navbar.Brand>

      </Container>
    </Navbar>
  );
}

export default Header;
