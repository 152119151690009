import React, { Component } from 'react';
import axios from 'axios';
import EventBlock from './EventBlock';
import VotingPage from "./VotingPage";

class VotingList extends Component {
  state = {
      events: [],
      votingIdToOpen: null,
      code : '',
      userId:''
  }

    handleVotingClick = (votingId) => {
          this.setState({ votingIdToOpen: votingId });
    };
  componentDidMount() {
    const { code } = this.props;
    axios.get(`http://127.0.0.1:8000/api/anon_users/${code}/get-vote/`)
      .then(res => {
        const  eventObj  = res.data.event;
        const ALLOWED_STATUS = ['voting', 'draft', 'waiting'];
        if (ALLOWED_STATUS.includes(eventObj.status)){
          this.setState({ events: [eventObj] , code: [code]  });
        }
        else{
          console.log("Нет доступных голосований");
        }
      })
  }

  render() {
    const { events,votingIdToOpen, code } = this.state;
    if (votingIdToOpen === null){
        return (
            <div>
                {events.map(event =>
                    <EventBlock
                        key={event.id}
                        eventName={event.name}
                        votingId={event.id}
                        initialStatus={event.status}
                        onClickVoting={this.handleVotingClick}
                    />
                )}
            </div>
        )
    }
    else
    return(
      <div>{votingIdToOpen &&
          <VotingPage
              votingId={votingIdToOpen}
              eventData={events.find(event => event.id === votingIdToOpen)}
              code={code}
              userId={this.props.userId}
          />}</div>
    )
  }
}

export default VotingList;
