import React, { useState } from 'react';
import axios from 'axios';
import { Row,Col,Container, Button } from 'react-bootstrap';
import './css/votingpage.css';

function VotingPage({ votingId, eventData, code, userId }) {
    const [selectedOption] = useState(null);
    const [votedQuestions, setVotedQuestions] = useState({}); // Объект для отслеживания проголосованных вариантов ответов

    const handleItemClick = (pollId, optionId, code, userId) => {
        axios.post(`http://127.0.0.1:8000/api/anon_users/${code}/add-vote/`, {
            'poll': pollId,
            'poll_option': optionId,
            'anon_user': userId
        })
            .then(response => {
                // Обновление состояния проголосованных вопросов
                setVotedQuestions(prevState => ({
                    ...prevState,
                    [pollId]: true, // Помечаем, что вопрос был проголосован
                }));

                // Проверяем, все ли варианты ответов для каждого вопроса были проголосованы
                const allQuestionsVoted = checkAllQuestionsVoted(eventData);

                if (allQuestionsVoted) {
                    console.log("все вопросы проголосованы");
                }
            })
            .catch(error => {
                console.error("Ответ не отправлен, пожалуйста, попробуйте позже");
            });
    };

    const checkAllQuestionsVoted = (eventData) => {
        for (const poll of eventData.polls) {
            if (!votedQuestions[poll.id]) {
                return false;
            }
        }
        return true;
    };

    return (
        <div className="container mt-5">
            {Object.keys(votedQuestions).length === eventData.polls.length ? (
                    <Container className="fluid d-flex justify-content-center align-items-center">
                        <Row>
                            <Col >Спасибо за участие в голосовании! Еще увидимся!</Col>
                            <Col>
                                <Button variant="primary">
                                    Вернуться на главную
                                </Button>
                            </Col>
                        </Row>
                    </Container>
            ) : (
                <Container>
                    <h2>{eventData.name}</h2>
                    <div className="mt-4 votingName">
                        <ul className="list-group">
                            {eventData.polls.map(poll => (
                                <div key={poll.id}>
                                    <h4 className={"votingName"}>{poll.name}</h4>
                                    <div className={"list-group"}>
                                        {poll.poll_options.map(option => (
                                            !votedQuestions[poll.id] ? (
                                                <div
                                                    key={option.id}
                                                    className={`list-group-item ${
                                                        selectedOption === option.id ? 'active' : ''
                                                    }`}
                                                    style={{
                                                        cursor: 'pointer',
                                                        textDecoration: 'none',
                                                    }}
                                                    onClick={() => handleItemClick(poll.id, option.id, code, userId)}
                                                >
                                                    {option.value}
                                                </div>
                                            ) : null
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </ul>
                    </div>
                </Container>
            )}
        </div>
    );
}

export default VotingPage;
