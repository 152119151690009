import React from 'react';
import { Container } from 'react-bootstrap';
function Footer() {
  return (
    <footer className="bg-white py-4">
      <Container>
        {/* Сюда можно добавить дополнительный контент, если потребуется */}
      </Container>
    </footer>
  );
}

export default Footer;