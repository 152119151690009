import React, { Component } from 'react';
import {Form, Button, Container, Image} from 'react-bootstrap';
import axios from "axios";

import "./css/invitation.css";
import Arrow from '../../assets/icons/ArrowRight.svg';
import ErrorComponent from "./ErrorComponent";
class EventCode extends Component {
    state = {
        code: '',
        errorMessage: '',
        userId : null,
    };
    handleUserIdUpdate = (userId) => {
        this.setState({ userId });
    };
    handleCodeSubmit = async () => {
        const {code} = this.state;
        const {showVotingList} = this.props;
        try {

            const response = await axios.get(`http://127.0.0.1:8000/api/anon_users/`);
            const codes = response.data.map(item => item.code);
            const user = response.data.find(item => item.code === code);
            if (codes.includes(code)) {
                showVotingList(code, user.id);
            }
            else
            {
                console.log("Такой код не существует");
                this.setState({ errorMessage: "Такой код не существует" });
            }
        }
        catch (error)
        {
            console.error("Invalid fetch request");
            this.setState({ errorMessage: "Ошибка при загрузке данных" });
        }
    }
    render()
    {
        const { errorMessage } = this.state;
        return (
            <Container className="d-flex flex-column align-items-center justify-content-center full-height">
                <Form.Group className="groupInvite position-relative mb-3" controlId="formCode">
                <Form.Control
                    size="lg"
                    type={"text"}
                    className={"inputCode"}
                    onChange={(e) => this.setState({code: e.target.value})}
                    placeholder="Введите код приглашения"
                />
                <Button onClick={this.handleCodeSubmit}
                        className="shift position-absolute top-50 translate-middle btn-inside-input">
                    <Image src={Arrow} width={40}  />
                </Button>
                </Form.Group>
                {errorMessage && <ErrorComponent errorMessage={errorMessage} />}
            </Container>
        );
    }
}

export default EventCode;
