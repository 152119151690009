import React, { Component } from 'react';
import Header from '../appHeader/Header';
import Footer from '../appFooter/Footer';
import VotingList from '../appVoting/VotingList';
import EventCode from '../appVoting/EventCode';

class App extends Component {
    state = {
        votingListVisible: false,
        code: '',
    };

    // Метод для обновления видимости VotingList
    showVotingList = (code, userId) => {
        this.setState({ votingListVisible: true, code: code, userId: userId  });
    };


    render() {
        const { votingListVisible, code,userId } = this.state;
        return (
            <div>
                <Header />
                {votingListVisible ? (
                    <VotingList code={code} userId={userId} />
                ) : (
                    <EventCode showVotingList={this.showVotingList} />
                )}
                <Footer />
            </div>
        );
    }
}

export default App;
