import React from 'react';
import { Container } from 'react-bootstrap';
import "./css/event.css";

function EventBlock({ eventName, votingId, initialStatus, onClickVoting }) {
  let statusCircleClass = 'status-circle';

  switch (initialStatus) {
    case 'waiting':
      statusCircleClass += ' waiting';
      initialStatus = 'Ожидание';
      break;
    case 'voting':
      statusCircleClass += ' voting';
      initialStatus = 'Активно';
      break;
    case 'completed':
      statusCircleClass += ' completed';
      initialStatus = 'Завершено';
      break;
    default:
      break;
  }

  const handleVotingClick = () => {
    onClickVoting(votingId);
  };

  return (
      <div className="event-block" onClick={() => handleVotingClick()}>
        <Container className="event-content">
          <div className="event-name">{eventName}</div>
          <div className="status-container">
            <span className={statusCircleClass}></span>
            <span className="statusTextClass">{initialStatus}</span>
          </div>
        </Container>
      </div>
  );
}

export default EventBlock;
